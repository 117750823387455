import { notifyText } from 'services/WalletService/config/constants';
import { getRegistrationDates as getRegDates } from 'store/airdrop/actions';
import actionTypes from 'store/airdrop/actionTypes';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { getToastMessage } from 'utils';

import { updateAirdropState } from '../reducer';

export function* signUp({ type }: ReturnType<typeof getRegDates>) {
  yield put(request(type));
  try {
    const address: UserState['address'] = yield select(userSelector.getProp('address'));

    const { status } = yield call(baseApi.signUp, { address });

    if (status === 200 || status === 201) {
      yield put(
        updateAirdropState({
          isRegistered: true,
        }),
      );
    }

    yield put(success(type));

    getToastMessage('success', notifyText.registration.success);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    getToastMessage('error', notifyText.registration.error);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGN_UP, signUp);
}
