import { URL } from 'appConstants';
import { UpdateAirdropReq } from 'types/requests';

import ajax from './ajax';

export const baseApi = {
  getRegistrationDates() {
    return ajax({
      method: 'get',
      url: URL.getRegistrationDates,
    });
  },
  signUp(data: { address: string }) {
    return ajax({
      method: 'post',
      url: URL.signUp,
      data,
    });
  },
  getRegistrationStatus(params: { address: string }) {
    return ajax({
      method: 'get',
      url: URL.getRegistrationStatus,
      params,
    });
  },
  getAirdropData(params: { address: string }) {
    return ajax({
      method: 'get',
      url: URL.getAirdropData,
      params,
    });
  },
  updateAirdropData(data: UpdateAirdropReq) {
    return ajax({
      method: 'post',
      url: URL.getAirdropData,
      data,
    });
  },
};
