import { agtiTokenIcon } from 'assets/images';
import { Chains, IChainType, IConnectWallet, IContracts, TChains } from 'types';
import { AbiItem } from 'web3-utils';

import { airdropAbi, crowdsaleAbi, erc20Abi, referralAbi, stakingAbi } from './abi';

export const chains: TChains = {
  [Chains.bsc]: {
    mainnet: {
      name: 'Binance-Smart-Chain',
      chainId: 56,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: 'https://bsc-dataseed.binance.org/',
      blockExplorerUrl: 'https://bscscan.com',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                56: 'https://bsc-dataseed.binance.org/',
              },
              chainId: 56,
              wcConfig: {
                projectId: 'd4705d64dc3e8782a53d333b7896bdad',
                chains: [56],
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                showQrModal: true,
              },
            },
          },
        },
      },
    },
    testnet: {
      name: 'Binance-Smart-Chain-Testnet',
      chainId: 97,
      nativeCurrency: {
        name: 'TBNB',
        symbol: 'TBNB',
        decimals: 18,
      },
      rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      blockExplorerUrl: 'https://testnet.bscscan.com',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
              },
              chainId: 97,
              wcConfig: {
                chains: [97],
                methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'personal_sign', 'eth_sign'],
                showQrModal: true,
                projectId: 'd4705d64dc3e8782a53d333b7896bdad',
              },
            },
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: Chains, type: IChainType): IConnectWallet => {
  const chain = chains[newChainName][type];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
      nativeCurrency: chain.nativeCurrency,
      rpc: chain.rpc,
      blockExplorerUrl: chain.blockExplorerUrl,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  agti,
  usdt,
  crowdsale,
  airdrop,
  referral,
  staking,
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.agti]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x42c0D67499C722FaE3cE695E90FcD0aecFF52940',
        },
        abi: erc20Abi as AbiItem[],
        symbol: 'AGTI',
        decimals: 0,
        img: agtiTokenIcon,
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0xE67feefC1c3C9725397a303B6Fcb5c099411e7b4',
        },
        abi: erc20Abi as AbiItem[],
        symbol: 'AGTI',
        decimals: 0,
        img: agtiTokenIcon,
      },
    },
    [ContractsNames.usdt]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x8d18D35Ed7Db9a8d956472CF8E971Efb98A06C89',
        },
        abi: erc20Abi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x55d398326f99059ff775485246999027b3197955',
        },
        abi: erc20Abi as AbiItem[],
      },
    },
    [ContractsNames.crowdsale]: {
      testnet: {
        address: {
          [Chains.bsc]: '0xa3d793e1400dff0cbf195950f0acBd30b262350f',
        },
        abi: crowdsaleAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0xBeD1cE0Ea3D7EFc3374225dF675F34FAFac885D7',
        },
        abi: crowdsaleAbi as AbiItem[],
      },
    },
    [ContractsNames.airdrop]: {
      testnet: {
        address: {
          [Chains.bsc]: '0xE0Da9CE7cC29281D2Be4e564924B868fE26c54A8',
        },
        abi: airdropAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x14bCE4df18Be12FD53092Fb9A47d80832092d8a9',
        },
        abi: airdropAbi as AbiItem[],
      },
    },
    [ContractsNames.referral]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x3D291567E269Bc745284256ba8B9Cac2d5da5d83',
        },
        abi: referralAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x32dA15502ff2eBe4207ec828293A6b9aA4b9d805',
        },
        abi: referralAbi as AbiItem[],
      },
    },
    [ContractsNames.staking]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x367107D0F3Cc76E8EaC27ad9c6da66eb0E94Fe25',
        },
        abi: stakingAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x820c136321e871B1044abD68a6A440554421237D',
        },
        abi: stakingAbi as AbiItem[],
      },
    },
  },
};
